import React from 'react'
import { useNavigate } from 'react-router-dom';
const Forbidden = () => {
    const history = useNavigate();
    return (
        <div className="vh-100 d-flex align-items-center justify-content-center bg-light">
            <div className="container py-5 text-dark  d-flex align-items-center justify-content-center bg-light">
                <div className="d-flex align-self-center">
                    <div className="row">
                        <div className="col-md-2 text-center">
                            <p><i className="fa fa-exclamation-triangle fa-5x"></i><br />Status Code: 403</p>
                        </div>
                        <div className="col-md-10">
                            <h3>Oops! Sorry...</h3>
                            <p>Sorry, your access is refused due to security reasons of our server and also our sensitive data.<br />Please go back to the previous page to continue browsing.</p>
                            <button className={`btn btn-primary`} onClick={() => history('/')}>Go Back</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Forbidden;