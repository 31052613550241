// sagas.js
import { call, put, takeLatest } from 'redux-saga/effects';
import { consentDetails } from '../../utils/Api';
import * as consentActions from './actionTypes';
import {
  consentCertificateDetailsSuccess,
  consentCertificateDetailsFailure
} from './actions';
import { REQUEST_STATUS } from '../../helpers/constants';
export function* getConsentCertitifcateDetails(action) {
  try {
    const { data, status } = yield call(consentDetails, action.payload);
    if (status === REQUEST_STATUS.FORBIDDEN && action.history) {
      action.history('/forbidden');
    }
    yield put(consentCertificateDetailsSuccess(data));
  } catch (error) {
    yield put(consentCertificateDetailsFailure(error.message));
  }
}

export default function* ConsentSaga() {
  yield takeLatest(consentActions.CONSENT_CERTIFICATE_DETAILS_REQUEST, getConsentCertitifcateDetails);
}
