import { takeLatest, call, put, takeEvery } from 'redux-saga/effects';
import { loginSuccess, loginFailure, getCurrentClientUserSuccess, getCurrentClientUserError } from './actions';
import { login } from '../../../utils/Api';
import { GET_CURRENT_CLIENT_USER, LOGIN_REQUEST } from './actionTypes';
import { STORAGE_KEY } from 'helpers/cc_browser_storage_helper';
import { jwtDecode } from 'jwt-decode';
import { getCurrentUser } from 'helpers/backend_helper';
import { AppConfiguration } from 'config';

export function* handleLogin(action) {
  const { email, password, navigate } = action.payload;
  const redirectTo = localStorage.getItem(STORAGE_KEY.REDIRECT_PATH) || "/dashboard";

  try {
    const payload = { user: { email, password } };
    const userData = yield call(login, payload);
    localStorage.setItem(STORAGE_KEY.AUTH_TOKEN, (userData.access_token));
    localStorage.setItem(STORAGE_KEY.ACCOUNT_ID, (userData?.data?.account_id));
    yield put(loginSuccess(userData));


  } catch (error) {
    yield put(loginFailure(error.message));
  }
}

function* onGetCurrentClientUser() {
  try {
    let token = localStorage.getItem(STORAGE_KEY.AUTH_TOKEN);
    if (!token) {
      console.error("Token not found")
      return;
    }
    let decodedToken = jwtDecode(token?.split(" ")[1]);
    const response = yield call(
      getCurrentUser,
      `${AppConfiguration.RAILS_BACKEND_URL}/api/v1/users/profiles/${decodedToken.sub}`,
      {
        token: token
      }
    )
    yield put(getCurrentClientUserSuccess(response))
  } catch (error) {
    yield put(getCurrentClientUserError(error.message))
  }
}

export function* loginSaga() {
  yield takeLatest(LOGIN_REQUEST, handleLogin);
  yield takeEvery(GET_CURRENT_CLIENT_USER, onGetCurrentClientUser)
}