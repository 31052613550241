import React, { useEffect, useState, useMemo, useCallback } from 'react';
import filterFactory from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import { Card, CardBody, Col, Row, FormGroup } from 'reactstrap';
import { dateCreatedFormatter, tooltipForColumnHeader } from 'helpers/utlis';
import { booleanFormatter, defaultFormatter, onHoverPopUpTooltipFormatter, timeDurationFormatter } from 'utils/Formatter';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchCampaignEvents, fetchCampaignFiltersData, fetchClientListFromGoal, fetchFilterdCampaignEvents } from 'store/certificate-vault/actions';
import i18n_keys from 'i18n_keys';
import { MetaTags } from 'react-meta-tags';
import Breadcrumbs from "components/common/Breadcrumb"
import SimpleBar from "simplebar-react"
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit'
import CustomSkeleton from 'components/common/CustomSkeleton';
import _ from "lodash";
import { PROSPECT_FILTERED_COLUMNS_NAMES, getProspectFilteredColumnsObjects } from 'helpers/CustomFilters/prospects_helpers';
import { STORAGE_KEY, bsh_getFilters, bsh_setFilters } from 'helpers/cc_browser_storage_helper';
import { FILTER_COMPONENT_TYPES, convertStringifiedNullToNull } from 'helpers/constants';
import CustomProspectFilters from "../../components/common/CustomFilters/index";
import ShowAppliedFilters from 'components/common/CustomFilters/ShowAppliedFilters';
import { fetchCampaignsRequest } from 'store/actions';
import { customSortCaret } from 'helpers/bootstrap_table_helper';
import { Link } from 'react-router-dom';
let cloned_prev_items = []

const mapActiveFilters = (prospectFilters) => {
    const activeFilters = prospectFilters.filter(item => item.is_active);
    if (activeFilters?.length > 0) {
        return activeFilters.map(item => {
            if (item.name === 'duration') {
                return {
                    name: item.name,
                    value: {
                        label: item.value.label,
                        value: item.comparator_value
                    }
                };
            } else if (item.component_type == 'multi_select') {
                return {
                    name: item.name,
                    value: item.value.map(option => option.value)
                };
            }
            else {
                return {
                    name: item.name,
                    value: item.value
                };
            }
        });
    } else {
        return activeFilters;
    }
};

const CAMPAIGN_FILTERS = {
    CAMPAIGN_NAME: 'campaign_name',
    CAMPAIGN_NAME_VALUE: 'campaign_name_value',
    CERTIFICATE_ID: "certificate_id",
    CERTIFICATE_ID_VALUE: 'certificate_id_value',
    DURATION: "duration",
    DURATION_VALUE: 'duration_value',
    PAGE_URL: "page_url",
    PAGE_URL_VALUE: 'page_url_value',
    IP_ADDRESS: "ip_address",
    IP_ADDRESS_VALUE: 'ip_address_value',
    LOCATION: "states",
    LOCATION_VALUE: 'location_value',
    BROWSER: "browser",
    BROWSER_VALUE: 'browser_value',
    COMPLETED: "completed",
    COMPLETED_VALUE: 'completed_value',
    CONSENT_CAPTURED: "consent_capture",
    CONSENT_CAPTURED_VALUE: "consent_capture_value",
    CID: 'cid',
    CID_VALUE: 'cid_value',
    AID: 'aid',
    AID_VALUE: 'aid_value',
    CLIENT_NAME: 'client_name',
    CLIENT_NAME_VALUE: 'client_name_value',
}

const CertificateVault = () => {

    const { campaignEvents, loading, last_page_fetched, has_next_page, campaignList, clientList, filters_data, loading_filters } = useSelector((state) => ({
        campaignEvents: state.CertificateVault.campaignEvents,
        loading: state.CertificateVault.loading,
        last_page_fetched: state.CertificateVault.last_page_fetched,
        has_next_page: state.CertificateVault.has_next_page,
        campaignList: state.campaignReducer.campaignList,
        clientList: state.CertificateVault.clientList,
        filters_data: state.CertificateVault.filter_data,
        loading_filters: state.CertificateVault.loading_filters
    }));

    const dispatch = useDispatch()

    useEffect(() => {
        if (Object.keys(filters_data).length > 0) {
            cloned_prev_items = _.cloneDeep(getProspectFilteredColumnsObjects(filters_data['browser'], filters_data['states'], filters_data['insurance_carrier']))
        }
    }, [filters_data])

    useEffect(() => {
        const final_query_params = queryParamsToJson(queryParams?.['filters']);
        dispatch(fetchFilterdCampaignEvents(final_query_params));
        dispatch(fetchCampaignsRequest());
        dispatch(fetchClientListFromGoal());
        dispatch(fetchCampaignFiltersData());
    }, [dispatch])

    const { t } = useTranslation();
    const [isActiveFilterModal, setIsActiveFilterModal] = useState(false);
    const [prospectFilterItems, setProspectFilterItems] = useState([]);
    const urlQueryParams = window.location.href;
    const activeFilters = bsh_getFilters(STORAGE_KEY.FILTERS.CAMPAIGN, 'active_filters')

    useEffect(() => {
        bsh_setFilters(STORAGE_KEY.FILTERS.CAMPAIGN, 'active_filters', activeFilters);
    }, [activeFilters]);

    const [filterModal, setFilterModal] = useState({
        campaign_name: bsh_getFilters(STORAGE_KEY.FILTERS.CAMPAIGN, CAMPAIGN_FILTERS.CAMPAIGN_NAME) || false,
        campaign_name_value: bsh_getFilters(STORAGE_KEY.FILTERS.CAMPAIGN, CAMPAIGN_FILTERS.CAMPAIGN_NAME_VALUE) || [],
        certificate_id: bsh_getFilters(STORAGE_KEY.FILTERS.CAMPAIGN, CAMPAIGN_FILTERS.CERTIFICATE_ID) || "",
        certificate_id_value: bsh_getFilters(STORAGE_KEY.FILTERS.CAMPAIGN, CAMPAIGN_FILTERS.CERTIFICATE_ID_VALUE) || false,
        duration: bsh_getFilters(STORAGE_KEY.FILTERS.CAMPAIGN, CAMPAIGN_FILTERS.DURATION) || "",
        duration_value: bsh_getFilters(STORAGE_KEY.FILTERS.CAMPAIGN, CAMPAIGN_FILTERS.DURATION_VALUE) || false,
        ip_address: bsh_getFilters(STORAGE_KEY.FILTERS.CAMPAIGN, CAMPAIGN_FILTERS.IP_ADDRESS) || "",
        ip_address_value: bsh_getFilters(STORAGE_KEY.FILTERS.CAMPAIGN, CAMPAIGN_FILTERS.IP_ADDRESS_VALUE) || false,
        page_url: bsh_getFilters(STORAGE_KEY.FILTERS.CAMPAIGN, CAMPAIGN_FILTERS.PAGE_URL) || "",
        page_url_value: bsh_getFilters(STORAGE_KEY.FILTERS.CAMPAIGN, CAMPAIGN_FILTERS.PAGE_URL_VALUE) || false,
        location: bsh_getFilters(STORAGE_KEY.FILTERS.CAMPAIGN, CAMPAIGN_FILTERS.LOCATION) || [],
        location_value: bsh_getFilters(STORAGE_KEY.FILTERS.CAMPAIGN, CAMPAIGN_FILTERS.LOCATION_VALUE) || false,
        browser: bsh_getFilters(STORAGE_KEY.FILTERS.CAMPAIGN, CAMPAIGN_FILTERS.BROWSER) || [],
        browser_value: bsh_getFilters(STORAGE_KEY.FILTERS.CAMPAIGN, CAMPAIGN_FILTERS.BROWSER_VALUE) || false,
        completed: bsh_getFilters(STORAGE_KEY.FILTERS.CAMPAIGN, CAMPAIGN_FILTERS.COMPLETED) || "",
        completed_value: bsh_getFilters(STORAGE_KEY.FILTERS.CAMPAIGN, CAMPAIGN_FILTERS.COMPLETED_VALUE) || false,
        consent_capture: bsh_getFilters(STORAGE_KEY.FILTERS.CAMPAIGN, CAMPAIGN_FILTERS.CONSENT_CAPTURED) || "",
        completed_value: bsh_getFilters(STORAGE_KEY.FILTERS.CAMPAIGN, CAMPAIGN_FILTERS.CONSENT_CAPTURED_VALUE) || false,
        cid: bsh_getFilters(STORAGE_KEY.FILTERS.CAMPAIGN, CAMPAIGN_FILTERS.CID) || "",
        cid_value: bsh_getFilters(STORAGE_KEY.FILTERS.CAMPAIGN, CAMPAIGN_FILTERS.CID_VALUE) || false,
        aid: bsh_getFilters(STORAGE_KEY.FILTERS.CAMPAIGN, CAMPAIGN_FILTERS.AID) || "",
        aid_value: bsh_getFilters(STORAGE_KEY.FILTERS.CAMPAIGN, CAMPAIGN_FILTERS.AID_VALUE) || "",
        client_name: bsh_getFilters(STORAGE_KEY.FILTERS.CAMPAIGN, CAMPAIGN_FILTERS.CLIENT_NAME) || false,
        client_name_value: bsh_getFilters(STORAGE_KEY.FILTERS.CAMPAIGN, CAMPAIGN_FILTERS.CLIENT_NAME_VALUE) || {},
    });

    const columns = [
        {
            dataField: "date",
            text: "Date",
            headerFormatter: (_column, colIndex, { sortElement, filterElement }) => {
                return tooltipForColumnHeader(_column, colIndex, { sortElement, filterElement }, 'date', 'Date', t(i18n_keys.DATE_TOOLTIP_MESSAGE))
            },
            sort: true,
            formatter: dateCreatedFormatter
        },
        {
            dataField: "campaign_name",
            text: "Campaign",
            headerFormatter: (_column, colIndex, { sortElement, filterElement }) => {
                return tooltipForColumnHeader(_column, colIndex, { sortElement, filterElement }, 'campaign_name', 'Campaign', t(i18n_keys.CAMPAIGN_NAME_TOOLTIP_MESSAGE))
            },
            sort: true,
            formatter: defaultFormatter
        },
        {
            dataField: "certificate_id",
            text: "Certificate ID",
            headerFormatter: (_column, colIndex, { sortElement, filterElement }) => {
                return tooltipForColumnHeader(_column, colIndex, { sortElement, filterElement }, 'certificate_id', 'Certificate ID', t(i18n_keys.CERTIFICATE_ID_TOOLTIP_MESSAGE))
            },
            formatter: (cellContent) => {
                return <Link className='link-primary' to={`/certificate/${cellContent}`} rel="noopener noreferrer"><span style={{ color: "#2f9ee9" }}>{cellContent}</span></Link>;
            }
        },
        {
            dataField: "is_completed",
            text: "Form Completed",
            headerFormatter: (_column, colIndex, { sortElement, filterElement }) => {
                return tooltipForColumnHeader(_column, colIndex, { sortElement, filterElement }, 'certificate_id', 'Form Completed', t(i18n_keys.FORM_COMPLETED_TOOLTIP_MESSAGE))
            },
            formatter: (cellContent) => { return booleanFormatter(cellContent, false) }
        },
        {
            dataField: "consent_captured",
            text: "Consent Captured",
            headerFormatter: (_column, colIndex, { sortElement, filterElement }) => {
                return tooltipForColumnHeader(_column, colIndex, { sortElement, filterElement }, 'certificate_id', 'Consent Captured', t(i18n_keys.CONSENT_CAPTURES_TOOLTIP))
            },
            formatter: (cellContent) => { return booleanFormatter(cellContent, false) }
        },
        {
            dataField: 'duration',
            text: 'Duration',
            headerFormatter: (_column, colIndex, { sortElement, filterElement }) => {
                return tooltipForColumnHeader(_column, colIndex, { sortElement, filterElement }, 'duration', 'Duration', t(i18n_keys.DURATION_TOOLTIP_MESSAGE))
            },
            sort: true,
            formatter: (cellContent) => {
                return (!!cellContent ? timeDurationFormatter(cellContent || '0.0') : 'In Progress');
            },
        },
        {
            dataField: 'source_page_url',
            text: "Page URL",
            headerFormatter: (_column, colIndex, { sortElement, filterElement }) => {
                return tooltipForColumnHeader(_column, colIndex, { sortElement, filterElement }, 'source_page_url', 'Page URL', t(i18n_keys.PAGE_URL_TOOLTIP_MESSAGE))
            },
            sort: true,
            style: { maxWidth: '250px', textOverflow: 'ellipsis', overflow: 'hidden', cursor: 'pointer' },
            formatter: ((cellContent, row, rowIndex) => onHoverPopUpTooltipFormatter(cellContent, row, rowIndex, "source_page_url"))
        },
        {
            dataField: "ip",
            text: "IP Address",
            headerFormatter: (_column, colIndex, { sortElement, filterElement }) => {
                return tooltipForColumnHeader(_column, colIndex, { sortElement, filterElement }, 'ip', 'IP Address', t(i18n_keys.IP_ADDRESS_TOOLTIP_MESSAGE))
            },
            formatter: defaultFormatter
        },
        {
            dataField: "location",
            text: "Location",
            headerFormatter: (_column, colIndex, { sortElement, filterElement }) => {
                return tooltipForColumnHeader(_column, colIndex, { sortElement, filterElement }, 'location', 'Location', t(i18n_keys.LOCATION_TOOLTIP_MESSAGE))
            },
            sort: true,
            formatter: defaultFormatter
        },
        {
            dataField: "os_browser_details",
            text: "Browser & OS",
            headerFormatter: (_column, colIndex, { sortElement, filterElement }) => {
                return tooltipForColumnHeader(_column, colIndex, { sortElement, filterElement }, 'os_browser_details', 'Browser & OS', t(i18n_keys.BROWSER_TOOLTIP_MESSAGE))
            },
            sort: true,
            formatter: defaultFormatter
        }
    ]

    const defaultSorted = [
        {
            dataField: "date",
            order: "desc",
        },
    ]

    const updateFilterItems = (prevItems, newItems) => {
        if (!prevItems) {
            return newItems;
        }
        const updatedItems = prevItems.map(prevItem => {
            const newItem = newItems.find(item => item.name === prevItem.name);
            if (newItem) {
                if (!_.isEqual(prevItem.options, newItem.options)) {
                    return { ...prevItem, options: newItem.options };
                }
            }
            return prevItem;
        });

        const additionalItems = newItems.filter(newItem =>
            !prevItems.some(prevItem => prevItem.name === newItem.name)
        );

        return [...updatedItems, ...additionalItems];
    };

    const onClickOpenFilterModal = () => {
        dispatch(fetchCampaignFiltersData());
        if (cloned_prev_items.length === 0) {
            return;
        }
        setIsActiveFilterModal(true);
        const mapped_filter_items_arr_objects = Object.values(mapped_filter_items);
        updateProspectFilterItems(mapped_filter_items_arr_objects);
    };

    const haveInsuranceCarrierOptionsChanged = (prevItems, newItems) => {
        const getOptions = (items, name) => items.find(item => item.name === name)?.options || [];

        const prevOptions = getOptions(prevItems || [], PROSPECT_FILTERED_COLUMNS_NAMES.INSURANCE_CARRIER);
        const newOptions = getOptions(newItems, PROSPECT_FILTERED_COLUMNS_NAMES.INSURANCE_CARRIER);

        return !_.isEqual(prevOptions, newOptions);
    };

    const debouncedUpdate = useCallback(
        _.debounce((mapped_filter_items_arr_objects) => {
            const filterOptionsAreChanged = haveInsuranceCarrierOptionsChanged(prospectFilterItems, mapped_filter_items_arr_objects)
            if (filterOptionsAreChanged && !loading_filters) {
                updateProspectFilterItems(mapped_filter_items_arr_objects);
            }
        }, 3000),
        [loading_filters]
    );

    useEffect(() => {
        const mapped_filter_items_arr_objects = Object.values(mapped_filter_items);
        debouncedUpdate(mapped_filter_items_arr_objects);

        return () => {
            debouncedUpdate.cancel();
        };
    }, [loading_filters]);

    const updateProspectFilterItems = (mapped_filter_items_arr_objects) => {
        setProspectFilterItems((prev_prospect_filter_items) => {
            const updatedFilterItems = updateFilterItems(prev_prospect_filter_items, mapped_filter_items_arr_objects);
            return updatedFilterItems
                .filter(item => !(item?.options && item.options.length === 0))
                .sort((a, b) => a.order - b.order);
        });
    };

    const onCloseFilterModal = () => {
        setIsActiveFilterModal(false);
    }

    const { SearchBar } = Search

    const mapped_filter_items = {
        ...cloned_prev_items,
        [PROSPECT_FILTERED_COLUMNS_NAMES.CAMPAIGN_NAME]: {
            ...cloned_prev_items[PROSPECT_FILTERED_COLUMNS_NAMES.CAMPAIGN_NAME],
            options: campaignList.map(campaign => ({ value: campaign.id, label: campaign.name }))
        },
        [PROSPECT_FILTERED_COLUMNS_NAMES.CLIENT_NAME]: {
            ...cloned_prev_items[PROSPECT_FILTERED_COLUMNS_NAMES.CLIENT_NAME],
            options: clientList.map(client => ({ value: client.uuid, label: client.account_name }))
        },
        [PROSPECT_FILTERED_COLUMNS_NAMES.INSURANCE_CARRIER]: {
            ...cloned_prev_items[PROSPECT_FILTERED_COLUMNS_NAMES.INSURANCE_CARRIER],
            options: (filters_data['insurance_carrier'] || []).map(carrier => ({ value: carrier, label: carrier }))
        }
    }

    const onSubmitOpenFilterModal = (values) => {
        const activeFilteredColumns = values.filter(item => {
            if (!item) {
                return
            }
            if (item.name === CAMPAIGN_FILTERS.DURATION) {
                return item.is_active && (item.value?.value) && (item.comparator_value?.match(/^\d+/) !== null);
            }
            if (item.component_type === FILTER_COMPONENT_TYPES.COMPARATOR) {
                return item.is_active && (!!item.value) && (item.comparator_value.trim() !== "");
            }
            if (typeof item.value === 'string') {
                return item.is_active && (item.value.trim() !== "");
            }
            if (item.component_type === FILTER_COMPONENT_TYPES.MULTI_SELECT) {
                return item.is_active && (item?.value?.length > 0);
            }
            return item.is_active && (!!item.value);
        });
        const filters = activeFilteredColumns || activeFilters
        bsh_setFilters(STORAGE_KEY.FILTERS.CAMPAIGN, 'active_filters', filters)
        setProspectFilterItems(filters);
        if (filters) {
            onClickLoadMoreData(filters, true);
            onCloseFilterModal();
            return
        }

        fetchNextBatch();
        onCloseFilterModal();
    }

    const onClickLoadMoreData = (prospect_filter_items = null, filterCount = null, last_page_fetched = null) => {
        const active_filtered_columns = { data: null }
        if (prospect_filter_items) {
            active_filtered_columns.data = mapActiveFilters(prospect_filter_items)
        }
        fetchNextBatch(filterCount ? active_filtered_columns.data : null, last_page_fetched)
    }

    const queryParams = {
        page_number: 1,
    };

    const fetchNextBatch = (filterModal = null, last_page_fetched = 0) => {
        if (filterModal) {
            const filterModalData = convertStringifiedNullToNull(filterModal)
            queryParams.filters = JSON.stringify(filterModalData || {})
        }
        queryParams.page_number = last_page_fetched + 1
        const final_query_params = queryParamsToJson(queryParams?.['filters']);
        !loading && dispatch(fetchFilterdCampaignEvents({ ...final_query_params, page_number: queryParams.page_number.toString() }));
    }

    useEffect(() => {
        if (activeFilters?.length > 0) {
            onSubmitOpenFilterModal(activeFilters)
        }
    }, []);

    const queryParamsToJson = (jsonString) => {
        const jsonArray = JSON.parse(jsonString || "[]");
        const jsonObject = {
            page_number: '1'
        };
        (jsonArray || []).forEach(item => {
            if (item.name === 'campaign_name' && Array.isArray(item.value)) {
                const campaignValues = item?.value
                jsonObject['campaign_ids'] = campaignValues;
            } else if (item.name === 'duration' && item.value !== null && item.value?.value?.match(/^\d+/) !== null) {
                const numericValue = item.value?.value?.match(/^\d+/)[0];
                jsonObject[item.name] = numericValue;
                jsonObject['duration_compare_type'] = item.value.label;
            } else if (item.name === 'date' && item.value !== null) {
                jsonObject['start_date'] = item.value?.value?.startDate;
                jsonObject['end_date'] = item.value?.value?.endDate;
            } else if (item.name === 'completed' && item.value !== null) {
                jsonObject[item.name] = item.value.value;
            } else if (item.name === 'consent_captured' && item.value !== null) {
                jsonObject[item.name] = item.value.value;
            } else {
                jsonObject[item.name] = typeof item.value === 'string' ? item.value.trim() : item.value
            }
        });
        return jsonObject;
    };

    const { filterCount, activeProspectFilterItems } = useMemo(() => {
        // return filteredColumns?.length
        if (prospectFilterItems?.length) {
            const filtered_active_prospects = prospectFilterItems.filter(item => item.is_active);
            return { filterCount: filtered_active_prospects?.length || 0, activeProspectFilterItems: filtered_active_prospects }
        }
        return ({ filterCount: 0, activeProspectFilterItems: [] });
    }, [prospectFilterItems]);

    const onClickRemoveFilter = (field_name) => {
        setProspectFilterItems((prev_prospect_filter_items) => {
            const cloned_prev_items = _.cloneDeep(prev_prospect_filter_items)
            const mapped_filter_items = cloned_prev_items.map((item) => {
                if (item.name !== field_name) {
                    return item;
                }
                updateActivefilters(item)
                if (item.component_type === FILTER_COMPONENT_TYPES.COMPARATOR) {
                    return ({ ...item, is_active: false, value: null, comparator_value: null })
                }
                return ({ ...item, is_active: false, value: null })
            });
            const filterd_filter_items = (mapActiveFilters(mapped_filter_items) || [])
            fetchNextBatch(filterd_filter_items)
            return mapped_filter_items;
        });
    }

    const updateActivefilters = (filter) => {
        const active_filters = activeFilters.filter(item => item.name !== filter.name);
        bsh_setFilters(STORAGE_KEY.FILTERS.CAMPAIGN, 'active_filters', active_filters);
    }


    return (
        <React.Fragment>
            <div className="page-content" id="page-content-wrapper">
                <MetaTags>
                    <title>{t(i18n_keys.CERTIFICATE_VAULT) + t(i18n_keys.PAGE_TITLE_SUFFIX)}</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title="Certificate Vault" breadcrumbItem="Certificate Vault" />
                    <CustomProspectFilters
                        isActive={isActiveFilterModal}
                        modalState={filterModal}
                        filterItems={prospectFilterItems}
                        onSubmitModalState={onSubmitOpenFilterModal}
                        onClickModalClose={onCloseFilterModal}
                        isActiveSearchBox={false}
                        key={isActiveFilterModal}
                    />
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <ToolkitProvider
                                        keyField="id"
                                        columns={columns}
                                        data={campaignEvents || []}
                                        search
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>
                                                <Row className="mb-2">
                                                    <Col md="auto" className="align-self-end">
                                                        <FormGroup className="mb-0">
                                                            <div>
                                                                <button
                                                                    className={`d-flex align-items-center btn  ${(filterModal?.isActiveModal || filterCount !== 0) ? 'filter-btn btn-blue' : ' btn-outline-blue'} ${filterCount === 0 ? 'filter-btn-w' : ''}`}
                                                                    type='button'
                                                                    onClick={onClickOpenFilterModal}
                                                                    disabled={loading_filters}
                                                                >  {loading_filters ? "Please wait..." : <> <i className="bx bx-filter font-size-18 align-middle me-2" />
                                                                    Filters {filterCount != 0 && <span className={filterModal?.isActiveModal ?
                                                                        'numberCircle' : 'numberCircle border-0'}> {filterCount} </span>}</>

                                                                    }
                                                                </button>
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xl="auto" className="align-self-end">
                                                        <div className="mt-1">
                                                            <div className="search-box d-inline-block mb-1">
                                                                <div className="position-relative">
                                                                    <SearchBar
                                                                        className="rounded"
                                                                        {...toolkitProps.searchProps}
                                                                    />
                                                                    <i className="bx bx-search-alt search-icon" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xl="auto" className="align-self-center">
                                                        <ShowAppliedFilters filtered_columns={activeProspectFilterItems} onClickRemoveFilter={onClickRemoveFilter} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xl="12">
                                                        <div className="table-responsive">
                                                            <SimpleBar className="table-screen-height">
                                                                {loading ? <CustomSkeleton rows={11} cols={7} /> :
                                                                    <BootstrapTable
                                                                        keyField={"id"}
                                                                        responsive
                                                                        columns={columns}
                                                                        data={campaignEvents || []}
                                                                        bordered={false}
                                                                        striped={false}
                                                                        defaultSorted={defaultSorted}
                                                                        classes={
                                                                            "table align-middle table-nowrap text-black thead-h50"
                                                                        }
                                                                        headerClasses=" custom-table-header text-black"
                                                                        rowClasses="tr-hover"
                                                                        headerWrapperClasses={"thead-light"}
                                                                        noDataIndication={t(i18n_keys.NO_CERTIFICATES_TO_DISPLAY)}
                                                                        filter={filterFactory()}
                                                                        sort={{ sortCaret: customSortCaret }}
                                                                        {...toolkitProps.baseProps}
                                                                    />
                                                                }
                                                            </SimpleBar>
                                                        </div>
                                                    </Col>
                                                    <Col xl="12">
                                                        <div className="mt-3 d-flex justify-content-between">
                                                            <span>
                                                                {has_next_page && <button
                                                                    className={`btn btn-primary`}
                                                                    onClick={() => onClickLoadMoreData(prospectFilterItems, filterCount, last_page_fetched)}>{loading ? 'Please wait...' : 'Load More'}</button>}
                                                            </span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )}
                                    </ToolkitProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default CertificateVault