import { useEffect, useState } from "react";

const useTabFocus = () => {
  const [hasFocus, setHasFocus] = useState(!document.hidden);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        setHasFocus(false);
      } else {
        setHasFocus(true);
      }
    };

    window.addEventListener("visibilitychange", handleVisibilityChange);

    return () => window.removeEventListener("visibilitychange", handleVisibilityChange);
  }, []);

  return hasFocus;
};

export default useTabFocus;