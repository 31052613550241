import React from "react";
import { Navigate } from "react-router-dom";
import UserProfile from "../pages/Authentication/user-profile";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import DefaultCampaignSetup from "../pages/Authentication/DefaultCampaignSetup";
import ForgetPassword from "../pages/Authentication/ForgetPassword";
import ResetPassword from "../pages/Authentication/ResetPassword";
import { Suspense } from "react"
import Dashboard from "../pages/Dashboard/index";
import Campaigns from "../pages/Campaigns/index";
import NewCampaign from "../pages/Campaigns/newCampaign";
import EditCampaign from "../pages/Campaigns/editCampaign";
import LoggedinView from "pages/ConsentCertificateDetails/LoggedinView";
import LoggedoutView from "pages/ConsentCertificateDetails/LoggedoutView";
import Users from "pages/Organization/Users";
import ReplaySession from "../pages/ReplayPage/ReplayPage"
import CertificateVault from "pages/CertificateVault/CertificateVault";

import Clients from "../pages/Admin/Clients"
import AdminUserSignUp from "pages/Admin/Authentication/AdminUserSignUp";
import AdminUserLogin from "pages/Admin/Authentication/AdminUserLogin";
import LoadingSpinner from "components/common/LoadingSpinner";
import Settings from "pages/Admin/Authentication/Settings";
import { lazyWithRetry } from "utils/common_utils";
import Forbidden from "../components/common/Forbidden";


export const Login = lazyWithRetry(() => import("../pages/Authentication/Login"))
export const LoginLazy = () => (
  <Suspense fallback={<LoadingSpinner />}>
    <Login />
  </Suspense>
)

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/campaigns", component: <Campaigns /> },
  { path: "/certificate-vault", component: <CertificateVault /> },
  { path: "/profile", component: <UserProfile /> },
  { path: "/campaigns/create", component: <NewCampaign /> },
  { path: "/campaigns/:id", component: <EditCampaign /> },
  { path: "/users", component: <Users /> },
  { path: "/forbidden", component: <Forbidden /> },
  { path: "/certificate/:certificateId", component: <LoggedinView /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> }
].map(route => ({ ...route, isAuthProtected: true })); //This will add an extra parameter isAuthProtected which will be use in App.js

const publicRoutes = [
  { path: "/login", component: <LoginLazy /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPassword /> },
  { path: "/reset-password", component: <ResetPassword /> },
  { path: "/register", component: <Register /> },
  { path: "/register/campaign", component: <DefaultCampaignSetup /> },
  { path: "/replay_session/:certificateId", component: <ReplaySession /> },
  { path: "/certificateDetails/:certificateId", component: <LoggedoutView /> },
  { path: "/api/v1/password/new", component: <ResetPassword create={true} /> }, //Create Password
  {
    path: "/",
    exact: true,
    component: <Navigate to='/login' />
  },
  { path: "*", component: <Navigate to="/login" /> }
].map(route => ({ ...route, isAuthProtected: false }));

const adminPublicRoutes = [
  { path: "/admin_signup", component: <AdminUserSignUp /> },
  { path: "/login", component: <AdminUserLogin /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to='/login' />
  },
  { path: "*", component: <Navigate to='/login' /> }

].map(route => ({ ...route, isAuthProtected: false }))

const adminUserAuthProtectedRoutes = [
  { path: "/client-users", component: <Clients /> },
  { path: "/forbidden", component: <Forbidden /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/client-users" />
  },
  { path: "*", component: <Navigate to='/client-users' /> },
  { path: "/settings", component: <Settings /> }
].map(route => ({ ...route, isAuthProtected: true }))

export { authProtectedRoutes, publicRoutes, adminPublicRoutes, adminUserAuthProtectedRoutes };
